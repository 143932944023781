<template>
  <v-card>

    <v-card-title class="text-h6">
      {{ $t('labels.timekeeping') }}
      <v-spacer></v-spacer>
      <v-btn small color="red darken-1" text @click="cancel">
        {{ $t('labels.close') }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div>
        <v-simple-table fixed-header height="calc(100vh - 235px)" class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">{{ $t('labels.date') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.shift') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.checkin_time') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.checkout_time') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.timekeeping_late') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.timekeeping_early') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="item in items" :key="item.id">
                <td>{{ formatDateTime(item.date, 'DD/MM/YYYY') }}</td>
                <td>{{ item.working_time_name }}</td>
                <td>{{ item.checkin_time }}</td>
                <td>{{ item.checkout_time }}</td>
                <td>{{ item.late }}</td>
                <td>{{ item.early }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row class="pt-3">
          <v-col cols="2"></v-col>
          <v-col cols="8">
            <v-pagination v-model="page" :length="totalPage" :total-visible="6"></v-pagination>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>

      </div>
    </v-card-text>

  </v-card>
</template>

<script>

import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "TimekeepingEmployee",
  components: {
  },
  props: {
    pFilters: {
      type: Object,
      default: () => { }
    }
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    filters: {},
    items: [],
    indexTxt: {},
    indexOptions: [],
    viewOption: 'view0',
  }),
  computed: {

  },
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  created() {
    this.filters = { ...this.pFilters }
    const indexTxt = {
      1: this.$t('labels.morning'),
      2: this.$t('labels.afternoon'),
      3: this.$t('labels.evening'),
      0: '???',
    }
    const indexOptions = [
      {
        text: indexTxt[1],
        value: 1
      },
      {
        text: indexTxt[2],
        value: 2
      },
    ]
    this.indexOptions = [...indexOptions]
    this.indexTxt = { ...indexTxt }
  },
  mounted() {
    this.getList()
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value }
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort }
    },
    cancel() {
      this.$emit('cancel')
    },
    getList: debounce(function () {
      httpClient.post('/timekeeping-list-by-employee', { ...this.filters, page: this.page, viewMode: this.viewOption }).then(({ data }) => {
        this.totalPage = data.totalPage
        this.items = [...data.rows]
      })
    }, 1000),
  }
}
</script>

<style scoped></style>
